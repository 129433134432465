
import { Vue, Component } from 'vue-property-decorator';
import LandingHero from '@/components/landingAndRegistrationComponents/LandingHero.vue';

@Component({
  components: { LandingHero },
})
export default class OurMissionPage extends Vue {
  get imgFormat(): string {
    return this.$store.state.calculateWindowWidth.windowWidth < 1279 ? '_mobile' : '';
  }

  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }
}
